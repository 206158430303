import { Carousel } from "../../components/Carousel/Carousel";
import { PortfolioItem } from "../../components/PortfolioItem/PortfolioItem";
import { Button } from "../../subcomponents/Button/Button";
import { CarouselTabs } from "../../subcomponents/CarouselTabs/CarouselTabs";
import styles from "./PortfolioCarousel.module.css";

export const PortfolioCarousel = (props: { windowWidth: number }) => {

    const portfolioItems = [
        <>
            <PortfolioItem  
                client="Benwerrin Kindergarten"
                project="Full Website Re-Design"
                testimonialName="Toni Lepore"
                testimonialRole="Director, Benwerrin Kindergarten"
                image="/img/benwerrin"
                url="https://www.benwerrinkindergarten.org.au"
                testimonialText="Jamie and Belinda were responsive, reliable, and flexible to meet our needs with improved design and functionality. The new, up-to-date website they developed significantly increased enrolments, and the kinder continues to grow effortlessly."
            />
        </>,
        <>
            <PortfolioItem
                client="Master Colour"
                project="Branding Identity and Website Build"
                testimonialName="Sash and Jelena Markovic"
                testimonialRole="Owners, Master Colour"
                image="/img/mastercolour"
                url="https://www.mastercolourpainting.com.au"
                testimonialText="These guys made it so easy for us with Belinda’s design expertise and Jamie’s backend experience. We are absolutely delighted with the final result of our new, modernised website, which met our requirements to the max."
            />
        </>
    ]

    return(
        <>
            <div className={styles.parent}>
                <Carousel content={portfolioItems} arrows={props.windowWidth < 1200 ? false : true} />
                <div className={styles.enquireNowContainer}>
                    <Button text="Enquire Now" isPrimary bgColor="#FFB80A" href="#contact" /> 
                </div>
            </div>
        </>
    )
}